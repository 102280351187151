import { graphql } from "gatsby";
import * as React from "react";
import { Constants, GATSBY_MOBILE_APP } from "../@types/Constants";
import IParagraph from "../@types/IParagraph";
import Layout from "../components/global/Layout/Layout";
import { IIndexPageProps } from "../pageHelpers/Index/IndexPageProps";
import ParagraphLayout from "../components/common/ParagraphLayout/ParagraphLayout";
import { getParagraph } from "../utils/paragraphHelpers";
import DeepLinking from "../components/common/DeepLinking/DeepLinking";
import SEO from "../components/global/SEO/SEO";
import ClientOnly from "../components/global/ClientOnly/ClientOnly";
import { setCRSSubSourceFromURL } from "../utils/helpers";
import { addPageTypeInGTMDataLayer } from "../utils/datalayers";
import WebFramed from "../components/common/WebFramed/WebFramed";
import { Framedview_User_Agent } from "../@types/Constants";
//import { addKoddiPageTag } from "../utils/KoddiTagsGTM";
// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema

//const HotelPage = (props: IHotelPageProps): JSX.Element => {
//export default class IndexPage extends React.Component<IIndexPageProps> {

const IndexPage = (props: IIndexPageProps) => {
  React.useEffect(() => {
    try {
      addPageTypeInGTMDataLayer("home");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  React.useEffect(() => {
    try {
      setCRSSubSourceFromURL(props.location.search);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  ///public render() {
  const _site = props.data.allRlhsite.edges[0].node;
  const _paragraphs = _site.relationships.paragraphs.map(getParagraph);
  const _pageBanner = _paragraphs.filter((p: IParagraph) => {
    return p
      ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
      : false;
  });
  if (GATSBY_MOBILE_APP) {
    return (
      // Window object not available at initial page load, it causes mobile UI issues. used ClientOnly component, This change only for mobile framed booking home page.
      <ClientOnly>
        <WebFramed />
        <Layout {...{ site: _site }}>
          <h1 className="sr-only">Sonesta Hotels and Resorts</h1>
          <SEO
            title="Sonesta - Hotels, Resorts and Cruises"
            description="Book a hotel room among our global properties in the United States, Canada, Egypt, Peru, Columbia, Chile, Ecuador, and the Caribbean for your next adventure."
            koddiTitle="Homepage"
          ></SEO>
          <DeepLinking location={props.location} pageName={"Homepage"} />
        </Layout>
      </ClientOnly>
    );
  }

  return (
    <Layout {...{ site: _site }}>
      <h1 className="sr-only">Sonesta Hotels and Resorts</h1>
      <SEO
        title="Sonesta - Hotels, Resorts and Cruises"
        description="Book a hotel room among our global properties in the United States, Canada, Egypt, Peru, Columbia, Chile, Ecuador, and the Caribbean for your next adventure."
        koddiTitle="Homepage"
      ></SEO>
      {_pageBanner}
      <DeepLinking location={props.location} pageName={"Homepage"} />
      <ParagraphLayout {..._paragraphs} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphMediaCta
              ...ParagraphPageTeaser
              ...ParagraphHelloRewardsBanner
              ...ParagraphTeaserList
              ...ParagraphFeaturedOffers
            }
          }
        }
      }
    }
    allBrand(
      filter: {
        relationships: {
          field_sites: { elemMatch: { machine_name: { eq: "sonesta" } } }
        }
      }
    ) {
      edges {
        node {
          path {
            alias
          }
          name
        }
      }
    }
    allHotel(
      filter: {
        relationships: {
          field_sites: { elemMatch: { machine_name: { eq: "sonesta" } } }
        }
      }
    ) {
      edges {
        node {
          name
          path {
            alias
          }
          description
          amenities {
            machine_name
          }
          hotel_code
          phone
          address {
            address_line1
            postal_code
            locality
            administrative_area
          }
        }
      }
    }
  }
`;

export default IndexPage;
